<!--
 * @Author: zoujp
 * @Date: 2020-08-13 15:57:20
 * @LastEditTime: 2020-08-13 17:02:37
 * @LastEditors: Please set LastEditors
 * @Description: 弹窗选择表情
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\comment\pop-emoji.vue
-->

<template>
  <el-popover
    class="pop-emoji"
    placement="bottom"
    trigger="click"
    v-model="showPopover"
  >
    <div class="emoji-list">
      <div
        class="emoji-mod"
        v-for="(item,idx) in emojiData" :key="idx"
        @click="selectEmoji(idx)"
      >
        <img :src="getFileUrl(item.file)" />
      </div>
    </div>
    <div slot="reference">
      <slot></slot>
    </div>
  </el-popover>
</template>
<script>
import emoji from "./emoji";

export default {
  name: "PopEmoji",
  components: { },
  props: {
  },
  data() {
    return {
      showPopover: false,
      emojiData: [],
      emojiKey: []
    };
  },
  mounted() {
    this.emojiData = Object.values(emoji);
    this.emojiKey = Object.keys(emoji);
  },
  methods: {
    /**
     * @description: 获取表情地址
     * @url :当前表情url
     * @return:
     */
    getFileUrl(url) {
      return `/commentEmoji/${url}`;
    }, /**
     * @description: 选择表情
     * @item :当前选中的表情数据下标
     * @return:
     */
    selectEmoji(idx) {
      const text = this.emojiKey[idx];
      this.$emit("selectEmoji", text);
    }
  }
};
</script>
<style lang='scss' scoped>
.emoji-list {
  width: 300px;
  height: 200px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  .emoji-mod{
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style lang='scss' >
.pop-emoji{

}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      staticClass: "pop-emoji",
      attrs: { placement: "bottom", trigger: "click" },
      model: {
        value: _vm.showPopover,
        callback: function ($$v) {
          _vm.showPopover = $$v
        },
        expression: "showPopover",
      },
    },
    [
      _c(
        "div",
        { staticClass: "emoji-list" },
        _vm._l(_vm.emojiData, function (item, idx) {
          return _c(
            "div",
            {
              key: idx,
              staticClass: "emoji-mod",
              on: {
                click: function ($event) {
                  return _vm.selectEmoji(idx)
                },
              },
            },
            [_c("img", { attrs: { src: _vm.getFileUrl(item.file) } })]
          )
        }),
        0
      ),
      _c(
        "div",
        { attrs: { slot: "reference" }, slot: "reference" },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
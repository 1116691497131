var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bus-comment-wrap" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.showContent,
            callback: function ($$v) {
              _vm.showContent = $$v
            },
            expression: "showContent",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: _vm.column.fieldType } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "div",
                  {
                    staticClass: "comment-title",
                    class: { "dis-expand": _vm.expandStatus === "shouqi" },
                  },
                  [
                    _c("div", { staticClass: "comment-tit-inner" }, [
                      _c("div", { staticClass: "txt" }, [
                        _c("span", [_vm._v(_vm._s(_vm.title))]),
                      ]),
                      _c("div", { staticClass: "message" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.power.includes("giveLike"),
                                expression: "this.power.includes('giveLike')",
                              },
                            ],
                            staticClass: "icon-wrap",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.giveLikeFn.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("en-icon", {
                              attrs: {
                                name: "dianzan-gongsiquan",
                                size: "16",
                                color:
                                  _vm.commentList.isUserLike === "000"
                                    ? "#3e90fe"
                                    : "#abb7c7",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "icon-num",
                                style:
                                  _vm.commentList.isUserLike === "000"
                                    ? "color: #3e90fe;"
                                    : "color: #636c78;",
                              },
                              [_vm._v(_vm._s(_vm.likeList.length))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.power.includes("comment"),
                                expression: "this.power.includes('comment')",
                              },
                            ],
                            staticClass: "icon-wrap",
                            staticStyle: { "margin-right": "10px" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.showFormFn.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("en-icon", {
                              attrs: {
                                name: "pinglun-gongsiquan",
                                size: "16",
                                color:
                                  _vm.getCommentLength() !== 0
                                    ? "#3e90fe"
                                    : "#abb7c7",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "icon-num",
                                style:
                                  _vm.getCommentLength() !== 0
                                    ? "color: #3e90fe;"
                                    : "color: #636c78;",
                              },
                              [_vm._v(_vm._s(_vm.getCommentLength()))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              !_vm.showForm &&
              _vm.commentContent.length == 0 &&
              _vm.likeList.length == 0
                ? _c("en-result", {
                    staticStyle: { height: "92px" },
                    attrs: { type: "NoData", iconSize: "small" },
                  })
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.expandStatus === "zhankai1",
                          expression: "expandStatus === 'zhankai1'",
                        },
                      ],
                      staticClass: "comment-list",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.power.includes("giveLike"),
                              expression: "this.power.includes('giveLike')",
                            },
                          ],
                          staticClass: "givelike-list",
                        },
                        [
                          _vm._l(_vm.likeList, function (item, index) {
                            return _c(
                              "userLogoInfo",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.isShow,
                                    expression: "item.isShow",
                                  },
                                ],
                                key: index,
                                staticClass: "givelike-mod",
                                attrs: {
                                  "user-name": item.displayName,
                                  "user-id": item.author,
                                  "is-show-circle": true,
                                },
                              },
                              [
                                _c("en-user-logo", {
                                  attrs: {
                                    "user-name": item.displayName,
                                    "image-url": _vm.getFileUrl(item.url),
                                    title: item.displayName,
                                    size: "30",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.likeList.length > _vm.limitNum,
                                  expression: "likeList.length > limitNum",
                                },
                              ],
                              staticClass: "givelike-more givelike-mod",
                            },
                            [
                              _c("en-icon", {
                                attrs: {
                                  name: "fujian-gengduo",
                                  size: "24",
                                  color: "#fff",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.changeGiveLike.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c("replyForm", {
                        ref: "firstForm",
                        attrs: { showForm: _vm.showForm },
                        on: { upData: _vm.upData },
                      }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.power.includes("comment"),
                              expression: "this.power.includes('comment')",
                            },
                          ],
                          staticClass: "comment-content",
                        },
                        _vm._l(_vm.firstThemeReplyList, function (item, index) {
                          return _c("commentItem", {
                            key: index,
                            attrs: {
                              item: item,
                              commentContent: _vm.commentContent,
                              limitNum: _vm.limitNum,
                              level: 0,
                            },
                            on: {
                              delComment: _vm.delComment,
                              upData: _vm.upData,
                            },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                this.power.includes("comment") &&
                                _vm.firstLeave > 0,
                              expression:
                                "this.power.includes('comment') && firstLeave > 0",
                            },
                          ],
                          staticClass: "comnent-more",
                          class: { cmore: _vm.firstLeave > 0 },
                          on: { click: _vm.showMoreFn },
                        },
                        [
                          _vm._v(" 查看更多 ( "),
                          _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.firstLeave)),
                          ]),
                          _vm._v(" ) "),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: zoujp
 * @Date: 2020-07-18 15:31:47
 * @LastEditTime: 2021-07-21 14:46:06
 * @LastEditors: Please set LastEditors
 * @Description: 一条评论
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\comment\commentItem.vue
-->
<template>
  <div class="comment-rows" v-show="item.isShow">
    <div class="row-mess">
      <div class="head">
        <userLogoInfo :user-id="item.author" :user-name="item.displayName" :is-show-circle="true">
          <en-user-logo
            :user-name="item.authorName"
            :image-url="getFileUrl(item.url)"
            :title="item.authorName"
            size="30"
          ></en-user-logo>
        </userLogoInfo>
      </div>
      <div class="mess">
        <div class="text">
          <span class="reply-name">{{ item.authorName }}：</span>
          <span v-show="isShowToPerson(item)">
            <span class="reply-text">回复：</span>
            <span class="reply-to">{{ "@" + showToPerson(item) }}：</span>
          </span>
          <div class="reply-content">
            <span v-for="(itm, idx) in getContent(item)" :key="idx">
              <span class="content-pad" v-if="itm.type === 0">{{ itm.text }}</span>
              <span class="reply-name content-pad" v-else-if="itm.type === 1">{{ itm.text }}</span>
              <span class="content-pad" v-else-if="itm.type === 2"
                ><img width="24" class="emoji" :src="buildEmoji(itm.text)"
              /></span>
              <!-- <span class="content-pad" v-elseif="item.type===">{{ itm.text }}</span> -->
            </span>
             <div class="iamge-container">
                <div v-for="(item, idx) in getContent(item)" :key="idx" class="">
                  <img v-if="item.type===3" class="pl-img" :src="item.text" alt="" />
                </div>
              </div>
          </div>
        </div>

        <div class="time">{{ getDateFormat(item.createTime) }}</div>
      </div>
      <div class="btns">
        <div class="btn-del" @click="del(item)" v-show="item.isDelete === '000'">删除</div>
        <div class="btn-reply" @click="reply(item)">回复</div>
      </div>
    </div>
    <replyForm
      :showForm="showForm"
      :data="item"
      @upData="upData"
      ref="listForm"
      style="padding: 0 70px; margin-bottom: 10px"
    >
    </replyForm>
    <div class="child-rows" :style="{'padding-left':level<1?'70px':'0px'}">
      <CommentItem
        v-for="(itm, idx) in childComment(item.id)"
        :style="{ borderBottom: setLastChdStyle(idx, item.id) ? '0' : '' }"
        :key="itm.id"
        :item="itm"
        :commentContent="commentContent"
        :limitNum="limitNum"
        :v-show="item.isShow"
        @delComment="delComment"
        @upData="upData"
        :level="newLevel"
      >
      </CommentItem>
      <div class="child-more" v-show="moreInfo(item).show" @click="childMore(item)">
        <span>查看更多（{{ moreInfo(item).num }}）</span>
      </div>
    </div>
  </div>
</template>
<script>
import { date } from "@/tools/date";
import userLogoInfo from "@/components/user-logo-info";
import { getFileUrl } from "@/tools/getFileUrl";
// import { commentService as CS } from "@/api/businessModel";
import replyForm from "./form";
import emoji from "./emoji";

export default {
  name: "CommentItem",
  components: { userLogoInfo, replyForm },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      }
    },
    level: {
      type: Number,
      default() {
        return 0;
      }
    },
    commentContent: {
      type: Array,
      default() {
        return [];
      }
    },
    limitNum: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      showForm: false, // 回复表单是否显示
      replyList: JSON.parse(JSON.stringify(this.commentContent || [])),
      isClickMoreMap: {} // 是否点击了查看更多
    };
  },
  computed: {

    newLevel() {
      return this.level + 1;
    },
    /**
     * @description: 获取子评论列表
     * @id : 当前评论id
     * @return:Array
     */
    childComment() {
      // eslint-disable-next-line
      return function (id) {
        const chd = this.replyList.filter((b) => b.parentId === id);
        chd.forEach((b, a) => {
          if (a < this.limitNum) {
            this.$set(b, "isShow", true);
          } else {
            this.$set(b, "isShow", !!this.isClickMoreMap[id]);
          }
        });
        return chd;
      };
    }
  },
  watch: {
    commentContent(val) {
      this.replyList = JSON.parse(JSON.stringify(val));
    }
  },
  mounted() {},
  methods: {
    /**
     * @description: 设置最后一个子节点样式
     * @curIdx : 当前行
     * @parentId :父级id
     */
    setLastChdStyle(curIdx, parentId) {
      const chd = this.replyList.filter((b) => b.parentId === parentId);
      if (curIdx === chd.length - 1) {
        return true;
      }
      return false;
    },
    /**
     * @description: 获取子评论数量
     * @id : 当前评论id
     * @return:Number
     */
    getChildLen(id) {
      const chd = this.commentContent.filter((b) => b.parentId === id);
      return chd.length;
    },
    /**
     * @description: 查看更多
     * @item : 当前数据
     * @return:
     */
    childMore(item) {
      this.$set(this.isClickMoreMap, item.id, true);
      this.replyList.forEach((b) => {
        if (b.parentId === item.id) {
          this.$set(b, "isShow", true);
        }
      });
      this.$forceUpdate();
    },
    /**
     * @description: 获取查看更多隐藏，剩余条数信息
     * @item : 当前评论数据
     * @return:
     */
    moreInfo(item) {
      if ((item.parentId || 0).toString() === "0") {
        const len = this.getChildLen(item.id);
        return {
          show: !this.isClickMoreMap[item.id] && len > this.limitNum,
          num: len - this.limitNum
        };
      }
      return {
        show: false
      };
    },
    /**
     * @description: 格式化评论时间
     * @itm : 当前数据
     * @return:
     */
    getDateFormat(time) {
      return date.getDateFormat2(time);
    },
    /**
     * @description: 是否显示回复某人
     * @itm : 当前数据
     * @return:
     */
    isShowToPerson(itm) {
      const re = itm.content?.reply?.id;
      return !!re;
    },
    /**
     * @description: 表情解析
     * @text : 评论内容
     * @return:
     */
    buildEmoji(text) {
      // eslint-disable-next-line
      const re = /\[([^\]\[]*)\]/g;

      const matches = text.match(re) || [];
      for (let j = 0, len = matches.length; j < len; ++j) {
        if (emoji[matches[j]]) {
          text = text.replace(matches[j], `/commentEmoji/${emoji[matches[j]].file}`);
          break;
        }
      }
      return text;
    },
    /**
     * @description: 获取评论内容
     * @item : 当前数据
     * @return:
     */
    getContent(item) {
      return item.content?.oneContent || [];
    },
    /**
     * @description: 获取回复了某人
     * @itm : 当前数据
     * @return:
     */
    showToPerson(itm) {
      return itm.content?.reply?.name || "";
    },
    /**
     * @description: 获取人员头像地址
     * @url : 头像地址
     * @return:
     */
    getFileUrl(url) {
      return getFileUrl(url, "000", "");
    },
    /**
     * @description: 删除评论
     * @return:
     */
    del(item) {
      this.$emit("delComment", item);
    },
    /**
     * @description: 删除子级
     * @return:
     */
    delComment(item) {
      this.$emit("delComment", item);
    },
    /**
     * @description: 回复评论
     * @return:
     */
    reply() {
      this.showForm = !this.showForm;
      if (this.showForm) {
        this.$nextTick(() => {
          this.$refs.listForm.inputFocus();
        });
      }
    },
    // 更新评论
    upData() {
      this.showForm = false;
      this.$emit("upData");
    }
  }
};
</script>

<style lang="scss" scoped>
.comment-rows {
  align-items: center;
  border-top: 1px solid #e8ecf2;
  border-bottom: 1px solid #e8ecf2;
.iamge-container{
  width:100%;
    display:flex;
      flex-wrap:wrap;
    .pl-img{
      max-width: 60px;
      max-height: 60px;
      margin:10px;
      object-fit:cover;
    }
}  .row-mess {
    display: flex;
    width: 100%;
    .head {
      width: 70px;
      height: 70px;
      display: flex;
      & /deep/ .pop-cls {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    .mess {
      flex: 1;
      color: #636c78;
      font-size: 12px;
      padding-top: 15px;
      text-align: left;
      .reply-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      .text {
        line-height: 24px;
        display: flex;
        .reply-name {
          color: #3e90fe;
          font-size: 12px;
        }
        .reply-to {
          color: #3e90fe;
          font-size: 12px;
        }
      }
      .time {
        color: #91a1b7;
        font-size: 12px;
      }
      .content-pad {
        margin-right: 5px;
      }
    }
    .btns {
      width: 100px;
      display: flex;
      font-size: 12px;
      align-items: center;
      .btn-del {
        color: #f76b6b;
        margin-right: 20px;
        cursor: pointer;
      }
      .btn-reply {
        color: #3e90fe;
        cursor: pointer;
      }
    }
  }
}
.comment-rows:last-child {
  border-bottom: 0;
}
.child-rows {
  padding-left: 60px;
  .child-more {
    color: #91a1b7;
    font-size: 12px;
    cursor: pointer;
  }
}
</style>

<!--
 * @Author: zoujp
 * @Date: 2020-07-16 15:26:46
 * @LastEditTime: 2021-07-21 14:24:30
 * @LastEditors: Please set LastEditors
 * @Description: 发布评论，回复评论表单
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\comment\form.vue
-->
<template>
  <div class="bus-comment-form" v-show="visible">
    <div class="form-wrap">
      <div class="text-tit">
        <div class="mod" v-for="(item, index) in toList" :key="index">
          <div class="text-mod">
            <span>{{ item.name }}</span>
          </div>
          <en-icon
            name="shanchu-liebiao"
            size="small"
            class="text-del"
            @click.native.stop="del(item)"
          >
          </en-icon>
        </div>
      </div>
      <div class="text-form">
        <el-input ref="inputTextarea" type="textarea" v-model="value" :rows="1"></el-input>
      </div>
      <div class="iamge-container">
        <div v-for="(item, index) in imageList" :key="item.text" class="image-item">
          <en-icon
            name="shanchu-liebiao"
            size="small"
            class="image-del"
            @click.native.stop="delimage(index)"
          ></en-icon>
          <img class="pl-img" :src="item.text" alt="" />
        </div>
      </div>
      <div class="text-list">
        <div class="btn-left">
          <popEmoji @selectEmoji="selectEmoji">
            <en-button
              class="icon-btn"
              icon="biaoqing"
              text
              color="#636C78"
              iconColor="#636C78"
              iconSize="14"
            >
              表情
            </en-button>
          </popEmoji>
          <en-button
            class="icon-btn"
            icon="-gongsiquan"
            text
            color="#636C78"
            iconColor="#636C78"
            iconSize="14"
            @click="$refs.addPerson.open()"
          >
            人员
          </en-button>
          <en-upload :on-change="handleUploadChange" :limitType="3" class="add-btn-box">
            <en-button
              class="icon-btn"
              icon="icongaoqingtupian"
              text
              color="#636C78"
              iconColor="#636C78"
              iconSize="14"
            >
              图片
            </en-button>
          </en-upload>
        </div>
        <div class="btn-right">
          <en-button v-loading="loading" class="btn-reply" @click="submit">评论</en-button>
        </div>
      </div>
    </div>
    <add-chats ref="addPerson" create-team @change="handleConfirmSelectPerson"></add-chats>
  </div>
</template>
<script>
import { commentService as CS } from "@/api/businessModel";
import addChats from "@/views/chat/view/addChats";
import popEmoji from "./pop-emoji";
import emoji from "./emoji";

export default {
  name: "BusCommentForm",
  components: { popEmoji, addChats },
  props: {
    showForm: {
      type: Boolean,
      default: false
    },
    // 向谁回复数据
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      value: "", // 内容
      toList: [], // @谁
      imageList: [], // 图片
      visible: this.showForm,
      loading: false
    };
  },
  watch: {
    showForm(val) {
      this.visible = val;
    }
  },
  mounted() {},
  created() {},
  methods: {
    delimage(index) {
      this.imageList.splice(index, 1);
    },
    handleUploadChange({ fileList, isAllUpload }) {
      console.log(3333333, fileList, isAllUpload);
      if (isAllUpload) {
        fileList.forEach((item) => {
          this.imageList.push({
            type: 3,
            text: item.url,
            data: {
              id: item.url,
              name: item.name
            }
          });
        });
      }
    },
    /**
     * @description: 文本域获取焦点
     * @text : 当前选中表情的对应的中文如：[鄙视]
     */
    selectEmoji(text) {
      this.value += text;
    },
    /**
     * @description: 文本域获取焦点
     */
    inputFocus() {
      this.$refs.inputTextarea.focus();
    },
    /**
     * @description: 发布评论
     */
    async submit() {
      const mess = [];
      // @人
      this.toList.forEach((b) => {
        mess.push({
          type: 1,
          text: `@${b.name}`,
          data: {
            id: b.id,
            name: b.name
          }
        });
      });
      mess.push(...this.imageList);
      const res = this.value.split(/(\[[^[\]]+\])/);
      res.forEach((b) => {
        if (b) {
          if (this.isEmoji(b)) {
            mess.push({
              type: 2,
              text: b
            });
          } else {
            mess.push({
              type: 0,
              text: b
            });
          }
        }
      });
      const content = {
        oneContent: mess
      };
      const p = {
        parentId: this.data.id || "0",
        mdId: this.$route.query.id,
        content: JSON.stringify(content),
        templateId: this.$route.query.templateId,
        viewType: this.$route.query.viewType || ""
      };
      try {
        this.loading = true;
        await CS.replyMd(p);
        this.loading = false;
        this.toList = [];
        this.value = "";
        this.$emit("upData");
      } catch (error) {
        this.loading = false;
      }
    },
    isEmoji(text) {
      let has = false;
      if (emoji[text]) {
        has = true;
      }
      return has;
    },
    // @人
    handleConfirmSelectPerson(res) {
      this.toList = res;
    },
    // 删除@人
    del(item) {
      this.toList.forEach((element, index) => {
        if (element.id === item.id) {
          this.toList.splice(index, 1);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.bus-comment-form {
  padding: 0 20px;
  .form-wrap {
    border: 1px solid #dce5ec;
    border-radius: 4px;
    .iamge-container {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      .image-item {
        position: relative;
        &:hover .image-del {
          display: block;
        }
        .image-del {
          position: absolute;
          display: none;
          right: 0px;
          top: 0px;
        }
      }
      .pl-img {
        max-width: 60px;
        max-height: 60px;
        margin: 10px;
        object-fit: cover;
      }
    }
    .text-tit {
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .mod {
        position: relative;
        &:not(:first-child) {
          margin-left: 10px;
        }
        .text-mod {
          max-width: 62px;
          width: 62px;
          text-align: center;
          border-radius: 10px;
          font-size: 12px;
          background-color: #ecf4fc;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #3e90fe;
          font-weight: 400;
        }
        &:hover {
          .text-del {
            display: block;
          }
        }
        .text-del {
          display: none;
          position: absolute;
          top: -3px;
          right: -3px;
        }
      }
    }
    .text-form {
      height: 56px;
      color: #333;
      font-size: 12px;
      & /deep/ .el-textarea__inner {
        height: 56px;
        outline: none;
        border: none;
        font-size: 12px;
      }
    }
    .text-list {
      height: 50px;
      line-height: 50px;
      border-top: 1px solid #dce5ec;
      background-color: #f5f7fa;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 10px;
      .btn-left {
        display: flex;
        flex: 1;
        align-items: center;
        .icon-btn {
          display: flex;
          align-items: center;
          margin-right: 10px;
          & /deep/ .en-icon-svg {
            margin-right: 3px;
          }
        }
      }
      .add-btn-box {
        padding-top: 5px;
      }
      .btn-right {
        width: 100px;
        display: flex;
        justify-content: flex-end;
        .btn-reply {
          width: 72px;
          height: 32px;
        }
      }
    }
  }
}
</style>

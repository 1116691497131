var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "bus-comment-form",
    },
    [
      _c("div", { staticClass: "form-wrap" }, [
        _c(
          "div",
          { staticClass: "text-tit" },
          _vm._l(_vm.toList, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "mod" },
              [
                _c("div", { staticClass: "text-mod" }, [
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ]),
                _c("en-icon", {
                  staticClass: "text-del",
                  attrs: { name: "shanchu-liebiao", size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.del(item)
                    },
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "text-form" },
          [
            _c("el-input", {
              ref: "inputTextarea",
              attrs: { type: "textarea", rows: 1 },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "iamge-container" },
          _vm._l(_vm.imageList, function (item, index) {
            return _c(
              "div",
              { key: item.text, staticClass: "image-item" },
              [
                _c("en-icon", {
                  staticClass: "image-del",
                  attrs: { name: "shanchu-liebiao", size: "small" },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.delimage(index)
                    },
                  },
                }),
                _c("img", {
                  staticClass: "pl-img",
                  attrs: { src: item.text, alt: "" },
                }),
              ],
              1
            )
          }),
          0
        ),
        _c("div", { staticClass: "text-list" }, [
          _c(
            "div",
            { staticClass: "btn-left" },
            [
              _c(
                "popEmoji",
                { on: { selectEmoji: _vm.selectEmoji } },
                [
                  _c(
                    "en-button",
                    {
                      staticClass: "icon-btn",
                      attrs: {
                        icon: "biaoqing",
                        text: "",
                        color: "#636C78",
                        iconColor: "#636C78",
                        iconSize: "14",
                      },
                    },
                    [_vm._v(" 表情 ")]
                  ),
                ],
                1
              ),
              _c(
                "en-button",
                {
                  staticClass: "icon-btn",
                  attrs: {
                    icon: "-gongsiquan",
                    text: "",
                    color: "#636C78",
                    iconColor: "#636C78",
                    iconSize: "14",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$refs.addPerson.open()
                    },
                  },
                },
                [_vm._v(" 人员 ")]
              ),
              _c(
                "en-upload",
                {
                  staticClass: "add-btn-box",
                  attrs: { "on-change": _vm.handleUploadChange, limitType: 3 },
                },
                [
                  _c(
                    "en-button",
                    {
                      staticClass: "icon-btn",
                      attrs: {
                        icon: "icongaoqingtupian",
                        text: "",
                        color: "#636C78",
                        iconColor: "#636C78",
                        iconSize: "14",
                      },
                    },
                    [_vm._v(" 图片 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn-right" },
            [
              _c(
                "en-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "btn-reply",
                  on: { click: _vm.submit },
                },
                [_vm._v("评论")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("add-chats", {
        ref: "addPerson",
        attrs: { "create-team": "" },
        on: { change: _vm.handleConfirmSelectPerson },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
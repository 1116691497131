<!--
 * @Author: zoujp
 * @Date: 2020-07-07 18:39:06
 * @LastEditTime: 2024-03-04 11:37:38
 * @LastEditors: Please set LastEditors
 * @Description: 业务建模评论点赞
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\comment\index.vue
-->
<template>
  <div class="bus-comment-wrap">
    <el-collapse v-model="showContent">
      <el-collapse-item :name="column.fieldType">
        <template slot="title">
          <div class="comment-title" :class="{ 'dis-expand': expandStatus === 'shouqi' }">
            <div class="comment-tit-inner">
              <div class="txt">
                <span>{{ title }}</span>
              </div>
              <div class="message">
                <div class="icon-wrap" @click.stop="giveLikeFn" v-show="this.power.includes('giveLike')">
                  <en-icon name="dianzan-gongsiquan" size="16" :color="commentList.isUserLike === '000' ? '#3e90fe' : '#abb7c7'"></en-icon>
                  <div class="icon-num" :style="commentList.isUserLike === '000' ? 'color: #3e90fe;' : 'color: #636c78;'">{{ likeList.length }}</div>
                </div>
                <div class="icon-wrap" style="margin-right: 10px;" @click.stop="showFormFn" v-show="this.power.includes('comment')">
                  <en-icon name="pinglun-gongsiquan" size="16" :color="getCommentLength() !== 0 ? '#3e90fe' : '#abb7c7'"></en-icon>
                  <div class="icon-num" :style="getCommentLength() !== 0 ? 'color: #3e90fe;' : 'color: #636c78;'">{{ getCommentLength() }}</div>
                </div>
                <!-- <div class="icon-wrap" v-show="this.power.includes('comment') && this.power.includes('giveLike')">
                  <en-icon :name="expandStatus" size="12" color="#3e90fe"></en-icon>
                </div> -->
              </div>
            </div>
          </div>
        </template>
        <en-result type="NoData" iconSize="small" style="height:92px;" v-if="!showForm && commentContent.length == 0 && likeList.length == 0" ></en-result>
        <div class="comment-list" v-else v-show="expandStatus === 'zhankai1'">
          <div class="givelike-list" v-show="this.power.includes('giveLike')">
            <userLogoInfo v-for="(item, index) in likeList" :key="index" v-show="item.isShow" :user-name="item.displayName" :user-id="item.author" :is-show-circle="true" class="givelike-mod">
              <en-user-logo :user-name="item.displayName" :image-url="getFileUrl(item.url)" :title="item.displayName" size="30"></en-user-logo>
            </userLogoInfo>
            <div class="givelike-more givelike-mod" v-show="likeList.length > limitNum">
              <en-icon name="fujian-gengduo" size="24" color="#fff" @click.native="changeGiveLike"></en-icon>
            </div>
          </div>
          <replyForm :showForm="showForm" ref="firstForm" @upData="upData"> </replyForm>
          <div class="comment-content" v-show="this.power.includes('comment')">
            <commentItem v-for="(item, index) in firstThemeReplyList" :key="index" :item="item" :commentContent="commentContent" :limitNum="limitNum" @delComment="delComment" @upData="upData"
            :level="0">
            </commentItem>
          </div>
          <div class="comnent-more" :class="{ cmore: firstLeave > 0 }" v-show="this.power.includes('comment') && firstLeave > 0" @click="showMoreFn">
            查看更多 ( <span class="num">{{ firstLeave }}</span> )
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getFileUrl } from "@/tools/getFileUrl";
import userLogoInfo from "@/components/user-logo-info";
import { commentService as CS } from "@/api/businessModel";
import replyForm from "./form";
import commentItem from "./commentItem";
import { resendMsg } from "../../../../../store/modules/chat/actions/msg";

// 引入用户详情弹窗组件
export default {
  name: "BMCommentColumn",
  components: {
    userLogoInfo,
    replyForm,
    commentItem
  },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      visible: false, // 是否显示，如果没有评论点赞的权限，不显示
      expandStatus: "zhankai1",
      limitNum: 5, // 默认显示5个评论，点赞
      isClickGiveLikeMore: false, // 是否点击了点赞更多按钮
      commentList: {}, // 评论源数据
      commentContent: [], // 评论内容
      firstThemeReplyList: [], // 评论列表
      likeList: [], // 点赞列表
      uploadUrl: {}, // 上传参数
      showForm: false, // 是否显示评论表单，默认隐藏
      showContent: [],
      firstLeave: 0, // 外层加载更多剩余评论数量
      title: "", // 标题
      power: [] // 权限comment , giveLike
    };
  },
  computed: {
    ...mapGetters(["userInfo", "urlParam"])
  },
  mounted() {
    this.getCommentList();
  },
  watch: {
    commentContent: {
      handler() {
        this.getFirstList();
        this.getLeaveNum();
      }
    }
  },
  methods: {
    /**
     * @description: 获取父级评论
     * @return:
     */
    getFirstList() {
      // 评论显示数据初始化
      const re = [];
      this.commentContent?.forEach((b, a) => {
        if (b.parentId === "0") {
          if (a < this.limitNum) {
            this.$set(b, "isShow", true);
          } else {
            this.$set(b, "isShow", false);
          }
          re.push(b);
        }
      });
      this.firstThemeReplyList = re;
    },
    /**
     * @description: 删除评论
     * @item :删除的当前行
     */
    delComment(item) {
      let msg = "";
      if ((item.parentId || 0).toString() === "0") {
        // 父级删除
        msg = "将同步删除该评论下的所有回复";
      } else {
        // 子集删除
        msg = "确定要删除评论/回复吗";
      }
      this.$confirm(msg, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.delComm(item);
      });
    },
    /**
     * @description: 删除评论公共调用方法
     * @itm :删除的当前行
     */
    async delComm(itm) {
      const params = { replyId: itm.id, templateId: this.businessData.mdInfo.templateId };
      await CS.deleteMdThemeReply(params);
      const This = this;
      this.commentContent.splice(
        This.commentContent.findIndex((item) => item.id === itm.id || item.parentId === itm.id),
        1
      );
    },
    /**
     * @description: 点赞方法
     * @return:
     */
    async giveLikeFn() {
      const params = { mdId: this.businessData.id, templateId: this.businessData.mdInfo.templateId, type: this.commentList.isUserLike === "000" ? "001" : "000" };
      await CS.mdLikeTheme(params);
      this.getCommentList();
    },
    /**
     * @description: 最外层查看更多
     * @return:
     */
    showMoreFn() {
      this.firstThemeReplyList.forEach((b) => {
        this.$set(b, "isShow", true);
      });
      this.firstLeave = 0;
      this.$forceUpdate();
    },
    /**
     * @description: 获取最外层剩余评论数量及显示隐藏状态
     * @return:
     */
    getLeaveNum() {
      let count = 0;
      const totalCount = this.getCommentLength();
      if (totalCount > this.limitNum) {
        count = totalCount - this.limitNum;
      }
      this.firstLeave = count;
    },

    /**
     * @description: 获取评论数量（parentId === 0）
     * @return:
     */
    getCommentLength() {
      let count = 0;
      this.firstThemeReplyList?.forEach((b) => {
        if (b.parentId === "0") {
          count += 1;
        }
      });
      return count;
    },
    /**
     * @description: 显示隐藏评论表单
     * @return:
     */
    showFormFn() {
      this.showForm = !this.showForm;
      if (this.showForm) {
        this.$nextTick(() => {
          this.$refs.firstForm.inputFocus();
        });
      }
    },
    /**
     * @description: 获取人员头像地址
     * @return:
     */
    getFileUrl(url) {
      return getFileUrl(url, "000", "");
    },
    /**
     * @description: 展开收起评论
     * @return:
     */
    async getCommentList() {
      const params = { mdId: this.businessData.id, templateId: this.businessData.mdInfo.templateId };
      // 测试数据
      // const params = { mdId: "320351089599778816", templateId: "310234985282998272" };
      this.commentList = await CS.queryCommentList(params);
      this.commentContent = this.commentList.themeReplyList;
      if (this.commentContent.length) {
        this.showContent = [this.column.fieldType];
      }
      this.getFirstList();
      this.getLeaveNum();

      // 点赞显示数据初始化
      const res = [];
      this.commentList.likeList.forEach((b, a) => {
        if (a < this.limitNum) {
          this.$set(b, "isShow", true);
        } else {
          this.$set(b, "isShow", false);
        }
        res.push(b);
      });
      this.likeList = res;

      // 判断是否有评论，点赞权限
      const mdBtnList = this.businessData.mdInfo?.mdBtnList || [];
      // 测试数据
      // const mdBtnList = [
      //   { btnKey: "comment" }, { btnKey: "giveLike" }
      // ];
      const comment = mdBtnList.some((item) => item.btnKey === "comment");
      const giveLike = mdBtnList.some((item) => item.btnKey === "giveLike");
      if (comment && giveLike) {
        this.title = "评论/点赞";
        this.power = ["comment", "giveLike"];
      } else if (comment && !giveLike) {
        this.title = "评论";
        this.power = ["comment"];
      } else if (!comment && giveLike) {
        this.title = "点赞";
        this.power = ["giveLike"];
      }
    },
    /**
     * @description: 展开收起评论
     * @return:
     */
    changeExpand() {
      this.expandStatus = this.expandStatus === "zhankai1" ? "shouqi" : "zhankai1";
    },
    /**
     * @description: 点赞
     * @return:
     */
    changeGiveLike() {
      this.isClickGiveLikeMore = !this.isClickGiveLikeMore;
      this.likeList.forEach((b, a) => {
        if (this.isClickGiveLikeMore) {
          this.$set(b, "isShow", true);
        } else if (a < this.limitNum) {
          this.$set(b, "isShow", true);
        } else {
          this.$set(b, "isShow", false);
        }
      });
      this.$forceUpdate();
    },
    // 更新评论
    async upData() {
      const params = { mdId: this.businessData.id, templateId: this.businessData.mdInfo.templateId };
      // 测试数据
      // const params = { mdId: "320351089599778816", templateId: "310234985282998272" };
      this.commentList = await CS.queryCommentList(params);
      this.commentContent = this.commentList.themeReplyList;
       this.showForm = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.bus-comment-wrap {
  width: 100%;
  border: 1px solid #dce5ec;
  border-radius: 4px;
  /deep/.el-collapse-item__header {
    background: #f6fafd;
    border-bottom: 1px solid #dce5ec;
    .el-collapse-item__arrow {
      color: #3e90fe;
    }
  }
  /deep/.el-collapse-item__content{
    padding-bottom: 0;
  }
  .comment-title {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    // padding: 0 20px;
    width: 100%;
    .comment-tit-inner {
      display: flex;
      align-items: center;
      text-align: left;
      .txt {
        width: 200px;
      }
      .message {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .icon-wrap {
          margin-left: 15px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .icon-num {
            margin-left: 2px;
            font-size: 12px;
            color: #3e90fe;
          }
        }
      }
    }
  }
  .dis-expand {
    border-bottom: 0;
  }
  .comnent-more {
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #dce5ec;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    font-size: 12px;
    color: #91a1b7;
    cursor: pointer;
  }
  .cmore {
    border-top: 0;
  }
  .comment-list {
    max-height: 600px;
    overflow-y: auto;
    .givelike-list {
      padding: 20px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      & /deep/ .el-popover__reference {
        display: flex;
        align-items: center;
      }
      .givelike-more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #a9b5c6;
        cursor: pointer;
      }
    }
    .givelike-mod {
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}
</style>

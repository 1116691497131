var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.item.isShow,
          expression: "item.isShow",
        },
      ],
      staticClass: "comment-rows",
    },
    [
      _c("div", { staticClass: "row-mess" }, [
        _c(
          "div",
          { staticClass: "head" },
          [
            _c(
              "userLogoInfo",
              {
                attrs: {
                  "user-id": _vm.item.author,
                  "user-name": _vm.item.displayName,
                  "is-show-circle": true,
                },
              },
              [
                _c("en-user-logo", {
                  attrs: {
                    "user-name": _vm.item.authorName,
                    "image-url": _vm.getFileUrl(_vm.item.url),
                    title: _vm.item.authorName,
                    size: "30",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "mess" }, [
          _c("div", { staticClass: "text" }, [
            _c("span", { staticClass: "reply-name" }, [
              _vm._v(_vm._s(_vm.item.authorName) + "："),
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowToPerson(_vm.item),
                    expression: "isShowToPerson(item)",
                  },
                ],
              },
              [
                _c("span", { staticClass: "reply-text" }, [_vm._v("回复：")]),
                _c("span", { staticClass: "reply-to" }, [
                  _vm._v(_vm._s("@" + _vm.showToPerson(_vm.item)) + "："),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "reply-content" },
              [
                _vm._l(_vm.getContent(_vm.item), function (itm, idx) {
                  return _c("span", { key: idx }, [
                    itm.type === 0
                      ? _c("span", { staticClass: "content-pad" }, [
                          _vm._v(_vm._s(itm.text)),
                        ])
                      : itm.type === 1
                      ? _c("span", { staticClass: "reply-name content-pad" }, [
                          _vm._v(_vm._s(itm.text)),
                        ])
                      : itm.type === 2
                      ? _c("span", { staticClass: "content-pad" }, [
                          _c("img", {
                            staticClass: "emoji",
                            attrs: {
                              width: "24",
                              src: _vm.buildEmoji(itm.text),
                            },
                          }),
                        ])
                      : _vm._e(),
                  ])
                }),
                _c(
                  "div",
                  { staticClass: "iamge-container" },
                  _vm._l(_vm.getContent(_vm.item), function (item, idx) {
                    return _c("div", { key: idx }, [
                      item.type === 3
                        ? _c("img", {
                            staticClass: "pl-img",
                            attrs: { src: item.text, alt: "" },
                          })
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "time" }, [
            _vm._v(_vm._s(_vm.getDateFormat(_vm.item.createTime))),
          ]),
        ]),
        _c("div", { staticClass: "btns" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.isDelete === "000",
                  expression: "item.isDelete === '000'",
                },
              ],
              staticClass: "btn-del",
              on: {
                click: function ($event) {
                  return _vm.del(_vm.item)
                },
              },
            },
            [_vm._v("删除")]
          ),
          _c(
            "div",
            {
              staticClass: "btn-reply",
              on: {
                click: function ($event) {
                  return _vm.reply(_vm.item)
                },
              },
            },
            [_vm._v("回复")]
          ),
        ]),
      ]),
      _c("replyForm", {
        ref: "listForm",
        staticStyle: { padding: "0 70px", "margin-bottom": "10px" },
        attrs: { showForm: _vm.showForm, data: _vm.item },
        on: { upData: _vm.upData },
      }),
      _c(
        "div",
        {
          staticClass: "child-rows",
          style: { "padding-left": _vm.level < 1 ? "70px" : "0px" },
        },
        [
          _vm._l(_vm.childComment(_vm.item.id), function (itm, idx) {
            return _c("CommentItem", {
              key: itm.id,
              style: {
                borderBottom: _vm.setLastChdStyle(idx, _vm.item.id) ? "0" : "",
              },
              attrs: {
                item: itm,
                commentContent: _vm.commentContent,
                limitNum: _vm.limitNum,
                "v-show": _vm.item.isShow,
                level: _vm.newLevel,
              },
              on: { delComment: _vm.delComment, upData: _vm.upData },
            })
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.moreInfo(_vm.item).show,
                  expression: "moreInfo(item).show",
                },
              ],
              staticClass: "child-more",
              on: {
                click: function ($event) {
                  return _vm.childMore(_vm.item)
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  "查看更多（" + _vm._s(_vm.moreInfo(_vm.item).num) + "）"
                ),
              ]),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }